import { EventsManager } from "./eventsManager";
import { ISelectedRegionConfig, SelectedRegion } from "./selectedRegion";
declare const gantt;
if (!gantt.ext) {
	gantt.ext = {};
}

const defaultConfig: ISelectedRegionConfig = {
	className: "gantt_click_drag_rect",
	useRequestAnimationFrame: true,
	callback: undefined,
	singleRow: false
};

const eventsManager = new EventsManager();

gantt.ext.clickDrag = eventsManager;

gantt.attachEvent("onGanttReady", () => {
	const config: ISelectedRegionConfig = { viewPort: gantt.$task_data, ...defaultConfig };
	if (gantt.config.click_drag) {
		const clickDrag = gantt.config.click_drag;
		config.render = clickDrag.render || defaultConfig.render;
		config.className = clickDrag.className || defaultConfig.className;
		config.callback = clickDrag.callback || defaultConfig.callback;
		config.viewPort = clickDrag.viewPort || gantt.$task_data;
		config.useRequestAnimationFrame = clickDrag.useRequestAnimationFrame === undefined ?
			defaultConfig.useRequestAnimationFrame : clickDrag.useRequestAnimationFrame;

		config.singleRow = clickDrag.singleRow === undefined ? defaultConfig.singleRow : clickDrag.singleRow;
		const selectedRegion = new SelectedRegion(config);
		gantt.ext.clickDrag.attach(selectedRegion, clickDrag.useKey);
	}
});

gantt.attachEvent("onDestroy", () => {
	eventsManager.destructor();
});
